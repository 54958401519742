import React, { useEffect, useState } from "react";
import { ReactComponent as Fb } from "../../assets/Icons/facebook.svg";
import { ReactComponent as Yt } from "../../assets/Icons/youtube.svg";
import { ReactComponent as LinkedIn } from "../../assets/Icons/linkedin.svg";
import { ReactComponent as Twitter } from "../../assets/Icons/twitter.svg";
import { ReactComponent as Insta } from "../../assets/Icons/instagram.svg";
import { ReactComponent as WA } from "../../assets/Icons/whatsapp.svg";
import { ReactComponent as X } from "../../assets/Icons/x.svg";
import { ReactComponent as Call } from "../../assets/Icons/call-support.svg";
import { ReactComponent as Email } from "../../assets/Icons/sms-tracking.svg";
import appDownload from "../../assets/images/app-download.png";
import apple from "../../assets/images/appstore.png";
import android from "../../assets/images/playstore.png";

import Button from "../Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/Navbar/logo.svg";
import pintarContactInfo from "../../config/pintarContactInfo";


import logo from "../../assets/Navbar/logo.svg";

const AddIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M5 10.1299H15" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 15.1299V5.12988" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);
const MinIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M5 10.1299H15" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

const Footer = () => {
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [quickLinksOpen, setQuickLinksOpen] = useState(false);
  const [userLinksOpen, setUserLinksOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const playstoreLink = "https://play.google.com/store/apps/details?id=pintarautomation.pk";
  const appstoreLink = "https://apps.apple.com/pk/app/pintar/id1557535423";

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth <= 510) {
        setIsMobile(true)
        console.log('mobile')
      } else {
        console.log('not mobile')
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
      if(window.innerWidth <= 510) setIsMobile(true);
      window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])
  
  return (
    <div className="footer-content-container" style={{background: '#242629', }}>
        <div className="footer-content">
          <div className="footer">
            <div className="footer-top">
              <div className="logo-social-links">
                <img src={logo}  />
                <div className="social-icons">
                  <div className="social-icon" onClick={() => {window.open("https://www.facebook.com/pintar.automation/");}}><Fb /></div>
                  <div className="social-icon" onClick={() => {window.open("https://www.linkedin.com/company/pintar-pk/");}}><LinkedIn /></div>
                  <div className="social-icon" onClick={() => {window.open("https://www.instagram.com/pintarofficial/");}}><Insta /></div>
                  <div className="social-icon" onClick={() => {window.open("https://twitter.com/pintar_pk/");}}><X /></div>
                  <div className="social-icon" onClick={() => {window.open("https://wa.me/923000746827/");}}><WA /></div>
                  <div className="social-icon" onClick={() => {window.open("https://www.youtube.com/channel/UCUUcGkEqyAGkkZTG14y-ErQ/");}}><Yt /></div>
                </div>
              </div>
              <div className="links-section">
                {isMobile ?
                <>
                  <div className="quick-links">
                    <div onClick={(e) => {e.stopPropagation(); setQuickLinksOpen(!quickLinksOpen);}} className="link-section-heading" style={{display: 'flex', alignItems: 'center', gap: '4px'}}>Quick links  {!quickLinksOpen ? <AddIcon /> : <MinIcon />}</div>
                    <div style={{display: quickLinksOpen ? 'flex' : 'none', justifyContent: 'center', gap: '12px', flexDirection: 'column'}}>
                    <Link className="link-name" to="/" onClick={() => window.scrollTo(0,0)}>
                      Home
                    </Link>
                    <div className="link-name" style={{width: 'min-content'}}>
                      <div onClick={(e) => {e.stopPropagation(); setCategoriesOpen(!categoriesOpen);}} style={{cursor: 'pointer', lineHeight: 0, display: 'flex', alignItems: 'center', gap: '4px'}}>Categories {!categoriesOpen ? <AddIcon /> : <MinIcon />}</div>
                      <div style={{transition: 'all 0.5s ease', display: !categoriesOpen ? 'none' : 'flex', justifyContent: 'center', flexDirection: 'column', gap: '8px', marginTop: '4px', paddingLeft: '12px'}}>
                      <Link className="link-name" style={{fontSize: '12px', color: 'gray', lineHeight: '16px'}} to="/interactive-home" onClick={() => window.scrollTo(0,0)}>Interactive Home</Link>
                      <Link className="link-name" style={{fontSize: '12px', color: 'gray', lineHeight: '16px'}} to="/mood-controlled-lighting" onClick={() => window.scrollTo(0,0)}>Mood Control Lighting</Link>
                      </div>
                    </div>
                    <Link className="link-name" to="/" onClick={() => window.scrollTo(0,0)}>
                      Blogs
                    </Link>
                    <Link className="link-name" to="/about-us" onClick={() => window.scrollTo(0,0)}>
                      About Us
                    </Link>
                    <Link className="link-name" to="/contact-us" onClick={() => window.scrollTo(0,0)}>
                      Contact Us
                    </Link>
                    </div>
                  </div>
                  <div className="quick-links">
                  <div onClick={(e) => {e.stopPropagation(); setUserLinksOpen(!userLinksOpen);}} className="link-section-heading" style={{display: 'flex', alignItems: 'center', gap: '4px'}}>Customer links  {!userLinksOpen ? <AddIcon /> : <MinIcon />}</div>
                  <div style={{display: userLinksOpen ? 'flex' : 'none', justifyContent: 'center', gap: '12px', flexDirection: 'column'}}>

                  {/* <Link className="link-name" to="/privacy-policy" onClick={() => window.scrollTo(0,0)}>
                    My Account
                  </Link> */}
                  <Link className="link-name" to="/terms-of-service/" onClick={() => window.scrollTo(0,0)}>
                    Term of Use
                  </Link>
                  <Link className="link-name" to="/privacy-policy/" onClick={() => window.scrollTo(0,0)}>
                    Privacy Policy
                  </Link>
                  </div>
                </div>
                </>
                  :
                  <>
                    <div className="quick-links">
                      <div className="link-section-heading">Quick links</div>
                      <Link className="link-name" to="/" onClick={() => window.scrollTo(0,0)}>
                        Home
                      </Link>
                      <div className="link-name" style={{width: 'min-content'}}>
                        <div onClick={(e) => {e.stopPropagation(); setCategoriesOpen(!categoriesOpen);}} style={{cursor: 'pointer', lineHeight: 0, display: 'flex', alignItems: 'center', gap: '4px'}}>Categories {!categoriesOpen ? <AddIcon /> : <MinIcon />}</div>
                        <div style={{transition: 'all 0.5s ease', display: !categoriesOpen ? 'none' : 'flex', justifyContent: 'center', flexDirection: 'column', gap: '8px', marginTop: '4px', paddingLeft: '12px'}}>
                        <Link className="link-name" style={{fontSize: '12px', color: 'gray', lineHeight: '16px'}} to="/interactive-home" onClick={() => window.scrollTo(0,0)}>Interactive Home</Link>
                        <Link className="link-name" style={{fontSize: '12px', color: 'gray', lineHeight: '16px'}} to="/mood-controlled-lighting" onClick={() => window.scrollTo(0,0)}>Mood Control Lighting</Link>
                        </div>
                      </div>
                      <Link className="link-name" to="/" onClick={() => window.scrollTo(0,0)}>
                        Blogs
                      </Link>
                      <Link className="link-name" to="/about-us" onClick={() => window.scrollTo(0,0)}>
                        About Us
                      </Link>
                      <Link className="link-name" to="/contact-us" onClick={() => window.scrollTo(0,0)}>
                        Contact Us
                      </Link>
                    </div>
                    <div className="quick-links">
                    <div className="link-section-heading">Customer links</div>
                    {/* <Link className="link-name" to="/privacy-policy" onClick={() => window.scrollTo(0,0)}>
                      My Account
                    </Link> */}
                    <Link className="link-name" to="/terms-of-service/" onClick={() => window.scrollTo(0,0)}>
                      Term of Use
                    </Link>
                    <Link className="link-name" to="/privacy-policy/" onClick={() => window.scrollTo(0,0)}>
                      Privacy Policy
                    </Link>
                  </div>
                  </>
                  }

                <div className="quick-links">
                  <div className="link-section-heading">Have A question?</div>
                  <div className="customer-support">
                    <Call />
                    <a className="link-name" href="https://wa.me/923000746827/" target="_blank">
                      +92 300 074 6827
                    </a>
                  </div>
                  <div className="customer-support">
                    <Email />
                    <a className="link-name" href="mailto:operations@pintar.pk" target="_top">
                      operations@pintar.pk
                    </a>
                  </div>
                  
                  <div className="footer-app-download">
                    <a target={'_blank'} className="link-name" href={playstoreLink}>
                      <img src={android} />
                    </a>
                    <a target={'_blank'} className="link-name" href={appstoreLink}>
                      <img src={apple} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="customer-links"></div>
              <div className="contact"></div>
            </div>

            <div className="footer-bottom-ribbon">
                <div style={{display: 'flex', alignItems:'center'}}>
                  © {(new Date()).getFullYear()} Pintar. All rights reserved
                </div>
                <div style={{display: 'flex', alignItems:'center', gap: '24px'}}>
                  <Link className="footer-bottom__link" to="/privacy-policy" onClick={() => window.scrollTo(0,0)}>
                    Privacy Policy
                  </Link>
                  <div style={{height:'8px', width:'8px',borderRadius:'50%',background: '#D5D6E1'}}></div>
                  <Link className="footer-bottom__link" to="/terms-of-service" onClick={() => window.scrollTo(0,0)}>
                    Terms of Use
                  </Link>
                </div>
              </div>
          </div>
        </div>
    </div>
  );
};

export default Footer;
